<template>
    <div class="page-container">
        <p class="main-title" >{{$route.query.main_title}}<a @click="reload"><i class="el-icon-refresh"></i></a></p>  
        <div class="page-desc">
           耗材入库是用户（管理员）将线下的耗材采购后，进行线上入库到系统中统一管理的操作。
         </div>   
		<TableVue ref="c_table" v-on="$listeners"></TableVue>
	</div>   
</template>
<script>
import TableVue from '../Common/ConsumableTable.vue'
export default {
    components:{
		TableVue,
    },
	 inject:['reload'],
   data() {
      return {
      }
   },
   created(){
		this.$nextTick(()=>{
			this.$refs.c_table.rUrl = {
				index:'/CseStorage/index',
				add:'/CseStorage/add',
				edit:'/CseStorage/edit',
				del:'/CseStorage/del',
				export:'/CseStorage/export'
			} 
			this.$refs.c_table.cardName =  '耗材入库单'
			this.$refs.c_table.cardType = 3
			this.$refs.c_table.getData();
		})
   },
   mounted(){
		
   },
   methods:{
		
   }

}
</script>

</style>